import { isDefined } from "@clipboard-health/util-ts";

import { compareGeoLocation } from "../GeoLocation/compareGeoLocation";
import type { MapViewWorkplace } from "./types";

export function sortMapViewWorkplaces(workplaces: MapViewWorkplace[]) {
  return [...workplaces].sort((a, b) => {
    if (a.shiftsCount === 0 && b.shiftsCount > 0) {
      return -1;
    }

    if (a.shiftsCount > 0 && b.shiftsCount === 0) {
      return 1;
    }

    if (!isDefined(a.attributes.location)) {
      return -1;
    }

    if (!isDefined(b.attributes.location)) {
      return -1;
    }

    return compareGeoLocation(a.attributes.location, b.attributes.location);
  });
}
