import { useTheme } from "@mui/material";
import { type ReactNode } from "react";
import { Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface ModalTransitionSwitchProps {
  children: ReactNode | ReactNode[];
  /**
   * Key used to identify the modal.
   * If not provided, the location pathname will be used instead.
   * This is useful when modals are nested and one modal renders on top of another,
   * which changes the location but should not unmount the previous modal.
   */
  modalKey?: string;
}

/**
 * Handles modal animations with these key features:
 * 1. Coordinated mounting/unmounting with animation times
 * 2. Support for nested modals via modalKey prop
 * 3. Maintains modal state during transitions
 *
 * The modalKey prop is crucial for nested modals:
 * - Without modalKey: Uses location.pathname (default behavior)
 * - With modalKey: Preserves parent modal during child modal transitions
 *
 * Animation timing:
 * - Enter: Immediate (0ms) to prevent delay when opening
 * - Exit: Uses theme.transitions.duration.leavingScreen to match MUI
 * - Data cleanup is coordinated with exit animation via setTimeout
 *
 * Example:
 * When a shift modal opens on top of a workplace modal:
 * - Workplace modal uses modalKey to stay mounted
 * - Shift modal animates in/out independently
 */
export function ModalTransitionSwitch(props: ModalTransitionSwitchProps) {
  const { children, modalKey } = props;
  const location = useLocation();
  const theme = useTheme();

  return (
    <TransitionGroup>
      <CSSTransition
        key={modalKey ?? location.pathname}
        timeout={{
          enter: 0,
          exit: theme.transitions.duration.leavingScreen,
        }}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
