import { isDefined } from "@clipboard-health/util-ts";
import { convertToGeoLocation } from "@src/appV2/Location/utils";

import { API_TYPES, type OpenShiftWorkplace } from "../Shift/OpenShifts/types";
import { type MapViewWorkplace } from "./types";
import type { WorkplaceOpenShiftCount } from "./useGetWorkplaceOpenShiftCount";

export type WorkplaceWithShiftsCount = Pick<
  WorkplaceOpenShiftCount,
  "userId" | "name" | "geoLocation" | "shiftsCount"
>;

function getWorkplaceLocation(workplace: WorkplaceWithShiftsCount) {
  const coordinates = workplace.geoLocation?.coordinates;
  return coordinates ? convertToGeoLocation(coordinates) : undefined;
}

export function getMapViewWorkplaces(
  // Necessary to get average rating for workplaces with shifts.
  openShiftWorkplacesMap: Map<string, OpenShiftWorkplace>,
  // Necessary to get workplaces without shifts and full workplace data.
  workplacesWithShiftsCount: WorkplaceWithShiftsCount[]
) {
  const mapViewWorkplaces = new Map<string, MapViewWorkplace>();

  workplacesWithShiftsCount.forEach((workplaceWithShiftsCount) => {
    const workplaceId = workplaceWithShiftsCount.userId;
    if (!isDefined(workplaceId)) {
      return;
    }

    const workplace = openShiftWorkplacesMap.get(workplaceId);
    if (isDefined(workplace)) {
      mapViewWorkplaces.set(workplaceId, {
        ...workplace,
        original: workplaceWithShiftsCount,
        shiftsCount: workplaceWithShiftsCount.shiftsCount,
      });
      return;
    }

    const location = getWorkplaceLocation(workplaceWithShiftsCount);
    if (!location) {
      // If we don't have a location, we can't add the workplace to the map.
      return;
    }

    // If the workplace is not in the open shifts data, it does not have
    // available shifts for the current filters.
    mapViewWorkplaces.set(workplaceId, {
      id: workplaceId,
      type: API_TYPES.workplace,
      original: workplaceWithShiftsCount,
      shiftsCount: 0,
      attributes: {
        location,
        name: workplaceWithShiftsCount.name,
        // Data not available in /facilityOpenShiftCount API,
        // but we won't show the rating for workplaces without shifts anyway.
        rating: { value: 0, count: 0 },
        requiresLunchBreak: false,
      },
    });
  });

  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(mapViewWorkplaces.values());
}
