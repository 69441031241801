import { ModalStatus, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import noop from "lodash/noop";
import { type ReactNode } from "react";
import { Route, type RouteProps } from "react-router-dom";

interface ModalRouteChildProps extends RouteProps {
  modalState: UseModalState;
}

interface ModalRouteProps extends Omit<RouteProps, "children" | "component" | "render"> {
  /**
   * `render` function of the underlying Route component with `modalState` prop added.
   * This is the only way to render ModalRoute (as opposed to using `children` or `component`).
   */
  render: (props: ModalRouteChildProps) => ReactNode;
  /**
   * Defines the path to navigate to when the modal is closed via modalState.closeModal().
   * This occurs when clicking the backdrop or close button inside the modal.
   * If not provided, history.goBack() will be used instead.
   */
  closeModalPath?: string;
  /**
   * If true, this modal's path can serve as a base path for other modals that may be rendered on top of it.
   *
   * For example, a shift bottom sheet can be rendered on top of a workplace open shifts modal.
   * In this case:
   * - Workplace open shifts modal path: /shift-discovery/workplace/:workplaceId/open-shifts
   * - Shift bottom sheet path: /shift-discovery/workplace/:workplaceId/open-shifts/shift/:shiftId
   */
  allowNestedPaths?: boolean;
  /**
   * If true, this modal's path will replace the current path on back instead of pushing a new entry onto the history stack.
   */
  shouldReplacePathOnBack?: boolean;
}

/**
 * Handles modal routing with these features:
 * 1. Route-based modal state management
 * 2. Configurable close behavior (closeModalPath)
 * 3. Support for nested routes (allowNestedPaths)
 * 4. Configurable history behavior on close (shouldReplacePathOnBack)
 *
 * Modal State:
 * Creates a route-controlled version of modalState (normally created by useModalState hook).
 * Instead of managing open/closed state internally, it derives the state from the current route:
 * - modalStatus: Derived from route matching
 * - closeModal: Navigates to closeModalPath or calls history.goBack()
 * - setModalStatus/openModal: Disabled (noop) as state is route-controlled
 *
 * Close behavior options:
 * - With closeModalPath: Navigates to specified path
 * - Without closeModalPath: Uses history.goBack()
 *
 * History behavior on close:
 * - shouldReplacePathOnBack=false: Pushes new entry to history stack (default)
 * - shouldReplacePathOnBack=true: Replaces current history entry
 *
 * Typically, shouldReplacePathOnBack is set to true for nested modals to prevent unexpected
 * back button behavior. For example, when closing a parent modal that had nested modals,
 * we want the back button to navigate to the previous parent modal rather than reopening
 * the last nested modal.
 *
 * Nested route support:
 * - allowNestedPaths=false: Exact path matching
 * - allowNestedPaths=true: Path prefix matching for nested modals
 */
export function ModalRoute(props: ModalRouteProps) {
  const {
    closeModalPath: goBackPath,
    render,
    allowNestedPaths = false,
    shouldReplacePathOnBack = false,
    ...restProps
  } = props;

  return (
    <Route
      {...restProps}
      render={(props) => {
        const { match, history } = props;
        const { pathname } = history.location;

        const isOpen = allowNestedPaths ? pathname.startsWith(match.url) : match.url === pathname;
        const modalStatus = isOpen ? ModalStatus.OPEN : ModalStatus.CLOSED;

        const modalState = {
          modalStatus,
          setModalStatus: noop,
          openModal: noop,
          modalIsClosed: modalStatus === ModalStatus.CLOSED,
          modalIsOpen: modalStatus === ModalStatus.OPEN,
          closeModal: () => {
            if (isDefined(goBackPath)) {
              if (shouldReplacePathOnBack) {
                history.replace(goBackPath);
              } else {
                history.push(goBackPath);
              }
            } else {
              history.goBack();
            }
          },
        };

        return render({ ...props, modalState });
      }}
    />
  );
}
