import { isDefined } from "@clipboard-health/util-ts";
import type { UseGetQueryOptions } from "@src/appV2/api";
import type { DateRange } from "@src/appV2/lib/Calendar/types";
import { convertToGeoLocation, type GeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { formatISO } from "date-fns";
import { useState } from "react";

import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { getIncludedOpenShiftsData } from "../Shift/OpenShifts/getIncludedOpenShiftsData";
import type { OpenShiftWorkplace } from "../Shift/OpenShifts/types";
import { type GetOpenShiftsResponse, useGetOpenShifts } from "../Shift/OpenShifts/useGetOpenShifts";
import { getShiftDiscoveryDefaultDateRange } from "../utils/getShiftDiscoveryDefaultDateRange";
import { getMapViewWorkplaces } from "./getMapViewWorkplaces";
import { sortMapViewWorkplaces } from "./sortMapViewWorkplaces";
import { useGetWorkplaceOpenShiftCount } from "./useGetWorkplaceOpenShiftCount";

interface UseMapWorkplacesDataProps {
  geoLocation?: GeoLocation;
}

export function useMapViewWorkplacesData(
  props: UseMapWorkplacesDataProps,
  options: UseGetQueryOptions<GetOpenShiftsResponse> = {}
) {
  const { geoLocation } = props;
  const { distance, license, ...otherFilters } = useShiftDiscoveryUserFiltersContext();

  const worker = useDefinedWorker();
  const coordinates =
    geoLocation ?? convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const [dateRange, setDateRange] = useState<DateRange>(getShiftDiscoveryDefaultDateRange());

  const areQueriesEnabled =
    Boolean(worker.geoLocation?.coordinates.length === 2 && license) &&
    isDefined(geoLocation) &&
    (options?.enabled ?? true);

  const {
    data: openShiftsData,
    isLoading: isLoadingOpenShiftsData,
    isError: isErrorOpenShiftsData,
  } = useGetOpenShifts(
    {
      filter: {
        start: {
          gte: dateRange.startDate.toISOString(),
          lte: dateRange.endDate.toISOString(),
        },
        qualifications: [license ?? ""],
        area: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radiusInMiles: distance,
        },
      },
    },
    { ...options, enabled: areQueriesEnabled }
  );

  const {
    data: workplacesWithShiftsCount = [],
    isLoading: isLoadingWorkplacesWithShiftsCount,
    isError: isErrorWorkplacesWithShiftsCount,
  } = useGetWorkplaceOpenShiftCount(
    {
      range: {
        start: formatISO(dateRange.startDate, { representation: "date" }),
        end: formatISO(dateRange.endDate, { representation: "date" }),
      },
      coordinates: [coordinates.longitude, coordinates.latitude],
      distance,
      qualification: license,
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
      isAgent: true,
    },
    {
      enabled: areQueriesEnabled,
    }
  );

  const { workplacesMap } = getIncludedOpenShiftsData(openShiftsData);
  const filteredOpenShifts = filterOpenShifts(
    { shifts: openShiftsData?.data ?? [], workplacesMap },
    otherFilters
  );

  // Make sure to only include workplaces for the filtered open shifts.
  const openShiftWorkplacesMap = new Map<string, OpenShiftWorkplace>();
  filteredOpenShifts.forEach((shift) => {
    const workplaceId = shift.relationships.workplace.data.id;
    const workplace = workplacesMap.get(workplaceId);
    if (isDefined(workplace) && !openShiftWorkplacesMap.has(workplaceId)) {
      openShiftWorkplacesMap.set(workplaceId, workplace);
    }
  });

  const mapViewWorkplaces = getMapViewWorkplaces(openShiftWorkplacesMap, workplacesWithShiftsCount);

  return {
    data: sortMapViewWorkplaces(mapViewWorkplaces),
    isLoading: isLoadingOpenShiftsData || isLoadingWorkplacesWithShiftsCount,
    isError: isErrorOpenShiftsData || isErrorWorkplacesWithShiftsCount,
    dateRange,
    setDateRange,
  };
}
