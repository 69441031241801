import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { eachDayOfInterval, formatISO, isWithinInterval, parseISO } from "date-fns";

import { HorizontalScrollView } from "./components/HorizontalScrollView";
import { PageHeaderWrapper } from "./components/PageHeaderWrapper";
import { useShiftDiscoveryUserFiltersContext } from "./Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "./paths";
import { OpenShiftDatePickerButton } from "./Shift/Calendar/OpenShiftDatePickerButton";
import { ShiftDateCell } from "./Shift/Calendar/ShiftDateCell";
import { ShiftDateListWrapper } from "./Shift/Calendar/ShiftDateListWrapper";
import { useShiftDiscoveryCalendarData } from "./Shift/Calendar/useShiftDiscoveryCalendarData";
import { ShiftInvitesCallout } from "./Shift/Invite/Callout";
import { UrgentShiftsCallout } from "./Shift/Urgent/Callout";
import { useShiftModalsDataContext } from "./useShiftModalsDataContext";
import { getBookedShiftTimeSlotsFromWorkerDayShifts } from "./utils/getBookedShiftTimeSlotsFromWorkerDayShifts";
import { getShiftDiscoveryDefaultDateRange } from "./utils/getShiftDiscoveryDefaultDateRange";

export function ShiftDiscoveryPageHeader() {
  const {
    workerShiftsByDate,
    openShiftsCountData,
    dateRange,
    openShiftsCountIsLoading,
    workerShiftsIsLoading,
  } = useShiftDiscoveryCalendarData({
    initialDateRange: getShiftDiscoveryDefaultDateRange(),
  });

  const { navigateToModal } = useShiftModalsDataContext();

  const { setDates, dates } = useShiftDiscoveryUserFiltersContext();
  const areDatesOutsideOfDefaultRange = dates.some(
    (date) =>
      !isWithinInterval(parseISO(date), {
        start: dateRange.startDate,
        end: dateRange.endDate,
      })
  );

  const daysToShow = areDatesOutsideOfDefaultRange
    ? dates.map((date) => parseISO(date))
    : eachDayOfInterval({
        start: dateRange.startDate,
        end: dateRange.endDate,
      });

  return (
    <PageHeaderWrapper>
      <Box sx={{ paddingBottom: 4 }}>
        <Stack direction="row">
          <OpenShiftDatePickerButton
            onClick={() => {
              navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
            }}
          />

          <ShiftDateListWrapper>
            {daysToShow.map((date) => {
              const formattedDate = formatISO(date, { representation: "date" });
              const isDateAvailable = isDefined(openShiftsCountData?.[formattedDate]);
              const workerDayShifts = workerShiftsByDate?.[formattedDate];
              const bookedShiftTimeSlots =
                getBookedShiftTimeSlotsFromWorkerDayShifts(workerDayShifts);
              const isLoading = openShiftsCountIsLoading || workerShiftsIsLoading;

              return (
                <ShiftDateCell
                  key={formattedDate}
                  size="medium"
                  date={date}
                  isLoading={isLoading}
                  isSelected={dates.includes(formattedDate)}
                  isDateAvailable={isDateAvailable}
                  bookedShiftTimeSlots={bookedShiftTimeSlots}
                  onToggleSelect={(isSelected) => {
                    setDates(isSelected ? [formattedDate] : []);
                  }}
                />
              );
            })}
          </ShiftDateListWrapper>
        </Stack>

        <HorizontalScrollView stretch sx={{ marginTop: 4, px: 5, alignSelf: "stretch" }}>
          <UrgentShiftsCallout />
          <ShiftInvitesCallout />
        </HorizontalScrollView>
      </Box>
    </PageHeaderWrapper>
  );
}
