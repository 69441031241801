/* eslint-disable import/max-dependencies */
import { Stack } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { isCapacitorPlatform } from "@src/appV2/lib";
import type { GeoLocation } from "@src/appV2/Location";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location/deviceLocation";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import {
  convertFromGoogleMapsLocation,
  convertToGeoLocation,
  convertToGoogleMapsLocation,
} from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { round } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { GoogleMapCustomMarkerWrapper } from "../GeoLocation/GoogleMapCustomMarkerWrapper";
import { MapMarkerExpanded } from "../GeoLocation/MapMarkerExpanded";
import { MapMarkerInactive } from "../GeoLocation/MapMarkerInactive";
import { MapMarkerLocation } from "../GeoLocation/MapMarkerLocation";
import { ShiftDiscoveryPageHeader } from "../PageHeader";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
} from "../paths";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";
import { MapControls } from "./MapControls";
import type { MapViewWorkplace } from "./types";
import { useMapViewWorkplacesData } from "./useMapViewWorkplacesData";

const googleMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  minZoom: 4,
  maxZoom: 16,
  styles: [
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

export function ShiftDiscoveryMapViewPage() {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [mapCenter, setMapCenter] = useState<GeoLocation | undefined>(undefined);

  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();
  const { setWorkplace, generateModalPath: generateWorkplaceModalPath } =
    useWorkplaceModalsDataContext();

  const { data: workplaces } = useMapViewWorkplacesData({ geoLocation: mapCenter });

  const worker = useDefinedWorker();

  const { data: isDeviceLocationPermissionGranted } = useIsDeviceLocationPermissionGranted();
  const { data: deviceGeoLocation } = useDeviceGeoLocationIfAllowed({
    enabled: !!isDeviceLocationPermissionGranted,
  });

  const shouldRequestLocationPermission =
    !deviceGeoLocation && !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  const workerCoordinates = worker.geoLocation?.coordinates;
  const homeLocation = workerCoordinates
    ? convertToGoogleMapsLocation(convertToGeoLocation(workerCoordinates))
    : undefined;

  const deviceLocation = deviceGeoLocation?.geoLocation
    ? convertToGoogleMapsLocation(deviceGeoLocation.geoLocation)
    : undefined;

  const workerLocation = deviceLocation ?? homeLocation;

  function onWorkplaceClick(workplace: MapViewWorkplace) {
    // TODO: this won't work with the contract of the new endpoint,
    // which doesn't include the full workplace object. We need to fetch
    // the workplace inside the modal instead.
    // https://github.com/ClipboardHealth/worker-app-bff/blob/main/packages/contract-worker-app-bff/src/lib/contracts/mapView.contract.ts
    setWorkplace(workplace.original);
    history.push(
      generateWorkplaceModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
        workplaceId: workplace.id,
      })
    );
  }

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <ShiftDiscoveryPageHeader />

      <Stack sx={{ position: "relative", flex: 1 }}>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={googleMapOptions}
          onLoad={(map) => {
            map.setZoom(12);
            setMap(map);
            if (workerLocation) {
              map.setCenter(workerLocation);
              setMapCenter(convertFromGoogleMapsLocation(workerLocation));
            }
          }}
        >
          {workerLocation && (
            <GoogleMapCustomMarkerWrapper position={workerLocation}>
              <MapMarkerLocation />
            </GoogleMapCustomMarkerWrapper>
          )}

          {workplaces.map((workplace) => {
            const { location, rating } = workplace.attributes;
            const coordinates = location ? convertToGoogleMapsLocation(location) : undefined;
            const ratingValue = round(rating.value ?? 0, 2);
            return (
              <GoogleMapCustomMarkerWrapper key={workplace.id} position={coordinates}>
                {workplace.shiftsCount > 0 ? (
                  <MapMarkerExpanded
                    rating={ratingValue === 0 ? undefined : ratingValue}
                    onClick={() => {
                      onWorkplaceClick(workplace);
                    }}
                  />
                ) : (
                  <MapMarkerInactive
                    onClick={() => {
                      onWorkplaceClick(workplace);
                    }}
                  />
                )}
              </GoogleMapCustomMarkerWrapper>
            );
          })}
        </GoogleMap>

        <MapControls
          canCenterToHome={!!homeLocation && !!map}
          canCenterToDevice={(!!deviceLocation && !!map) || shouldRequestLocationPermission}
          onCenterToHome={() => {
            if (map && homeLocation) {
              map.setCenter(homeLocation);
            }
          }}
          onCenterToDevice={() => {
            if (!deviceLocation && shouldRequestLocationPermission) {
              navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
              return;
            }

            if (map && deviceLocation) {
              map.setCenter(deviceLocation);
            }
          }}
          onZoomIn={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom + 1);
            }
          }}
          onZoomOut={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom - 1);
            }
          }}
        />
      </Stack>

      <ShiftDiscoverySearchModeSwitcher />
    </Stack>
  );
}
/* eslint-enable import/max-dependencies */
