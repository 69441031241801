import { Stack } from "@mui/material";

import { IconButton } from "../components/IconButton";

interface MapControlsProps {
  canCenterToHome: boolean;
  canCenterToDevice: boolean;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onCenterToHome: () => void;
  onCenterToDevice: () => void;
}

export function MapControls({
  canCenterToHome,
  canCenterToDevice,
  onZoomIn,
  onZoomOut,
  onCenterToHome,
  onCenterToDevice,
}: MapControlsProps) {
  return (
    <Stack>
      {(canCenterToHome || canCenterToDevice) && (
        <Stack
          direction="row"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: theme.borderRadius?.xLarge,
            position: "absolute",
            top: theme.spacing(4),
            left: theme.spacing(4),
          })}
        >
          {canCenterToHome && (
            <IconButton invert iconType="house" variant="contained" onClick={onCenterToHome} />
          )}
          {canCenterToDevice && (
            <IconButton
              invert
              iconType="navigation-arrow"
              variant="contained"
              onClick={onCenterToDevice}
            />
          )}
        </Stack>
      )}

      <Stack
        direction="row"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.tertiary,
          borderRadius: theme.borderRadius?.xLarge,
          position: "absolute",
          top: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <IconButton invert iconType="minus" variant="contained" onClick={onZoomOut} />
        <IconButton invert iconType="plus" variant="contained" onClick={onZoomIn} />
      </Stack>
    </Stack>
  );
}
