import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { facilitySchema } from "@src/appV2/Facilities/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export const requestSchema = z.object({
  coordinates: z.array(z.number()),
  distance: z.number(),
  isAgent: z.boolean(),
  qualification: z.string(),
  range: z.object({ start: z.string(), end: z.string() }),
  specialities: z.object({
    hasSedationExperience: z.boolean(),
    hasTrayAssemblyExperience: z.boolean(),
  }),
});

const workplaceOpenShiftCountSchema = facilitySchema.extend({ shiftsCount: z.number() });
export type WorkplaceOpenShiftCount = z.infer<typeof workplaceOpenShiftCountSchema>;

const responseSchema = z.array(workplaceOpenShiftCountSchema);

export type WorkplaceOpenShiftCountRequestParams = z.infer<typeof requestSchema>;
export type WorkplaceOpenShiftCountResponse = z.infer<typeof responseSchema>;

export const GET_WORKPLACE_OPEN_SHIFT_COUNT_PATH = "/calendar/facilityOpenShiftCount";
export const GET_WORKPLACE_OPEN_SHIFT_COUNT_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_WORKPLACE_OPEN_SHIFT_COUNT_PATH}`;

// Necessary to get workplaces without shifts.
// This is temporary until we have a proper API endpoint for map view data.
// https://linear.app/clipboardhealth/issue/SPRTA-1032/add-endpoint-that-fetches-basic-workplaces-within-a-given-radius
export function useGetWorkplaceOpenShiftCount(
  params: WorkplaceOpenShiftCountRequestParams,
  options: UseGetQueryOptions<WorkplaceOpenShiftCountResponse> = {}
): UseQueryResult<WorkplaceOpenShiftCountResponse> {
  return useGetQuery({
    url: GET_WORKPLACE_OPEN_SHIFT_COUNT_URL,
    queryParams: params,
    requestSchema,
    responseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading map data",
      logErrorMessage: APP_V2_APP_EVENTS.GET_MAP_VIEW_FACILITY_OPEN_SHIFT_COUNT_FAILURE,
    },
    ...options,
  });
}
